<template>
  <div class="footer">
    <ul class="container">
      <li>
        <dl>
          <dt>联系我们</dt>
          <dd>客服热线：{{info.contactTel}}</dd>
          <dd>联系地址：{{info.companyAddress}}</dd>
        </dl>
      </li>
      <li class="Qrcode">
        <dl>
          <dt>
            <img class="footerQrcode" :src="footerQrcode" />
          </dt>
          <dd>企业公众号</dd>
        </dl>
      </li>
    </ul>
    <div class="footerBottom">
      <div style="color: #fff; font-size: 12px">
        Copyright © {{info.companyName||'探途物网科技'}} Rights Reserved
        &nbsp;&nbsp;&nbsp;
        <a class="hover" rel="noreferrer" target="_blank" href="https://beian.miit.gov.cn">网站备案/许可证号：{{beian}}</a>&nbsp;
        <img :src="footerBottomImg" alt="" style="top: 2px; position: relative" />
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapState,
  // mapMutations
} from 'vuex'
export default {
  components: {},
  data() {
    return {
      beian:'粤ICP备2023080707号',
      base: window.baseUrl,
      footerBottomImg: require("@/assets/img/beian.png"),
      info: {
        footerQrcode: require("@/assets/img/erweimadibu.png"),

      },
      footerQrcode:''
    };
  },
  computed: {
    ...mapState(['websiteCompanyDetail'])
  },
  watch: {
    websiteCompanyDetail: {
      handler(res){
        // console.log(res)
        if(res){
          this.info = res
          // this.footerBottomImg=window.baseUrl+res.wxQrCode
          this.footerQrcode=window.baseUrl+res.wxQrCode
        }
      },
      deep: true
    }
  },
  mounted() {
    //  this.$nextTick(()=>{
    //   let info=JSON.parse(window.localStorage.getItem('websiteCompanyDetail'))
    //   this.info=info
    //  });

  }
};
</script>

<style lang="scss">
// @import "@/assets/css/public/layout.scss";
.footer {
  background-color: #252c3c;
  height: 250px;
  width: 100%;
  .hover {
    margin: 0 5px;
    display: inline-block;
    text-decoration: none;
    color: #fff;
    &:hover {
      color: #ccc;
    }
  }
  ul {
    overflow: hidden;
    padding-top: 40px;
    li {
      color: #fff;
      float: left;
      .footerQrcode {
        max-width: 94px;
      }
      text-align: left;
      dt {
        font-size: 16px;
        margin-bottom: 30px;
      }
      dd {
        font-size: 12px;
        margin-bottom: 15px;
      }
    }
    .Qrcode {
      float: right;
      dt {
        font-size: 12px;
        margin-bottom: 10px;
      }
      dd {
        text-align: center;
      }
    }
  }
  // ul
  .footerBottom {
    width: 100%;
    padding: 12px 0;
    text-align: center;
    border-top: 2px solid #676c77;
  }
}
</style>