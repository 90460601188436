<template>
  <el-carousel height="600px"
               arrow="never">
    <el-carousel-item v-for="item in imgArr"
                      :key="item.id">
      <!-- <div class="item"> -->
      <img :src="item.src"
           alt="" height="100%">
      <!-- </div> -->
    </el-carousel-item>
  </el-carousel>
</template>
<script>

export default {
  data () {
    return {

    }
  },
  props: {
    imgArr: []
  },
  components: {

  }
}
</script>
<style lang="scss">
.el-carousel__item .item {
  position: relative;
  overflow: hidden;
  height: 100%;
  img {
    width: 100%;
    // height: 100%;
    // position: absolute;
    // left: 0;
    // top: 0;
  }
}
</style>


