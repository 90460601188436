<template>
  <div class="long_rent">
    <div class="cover_img">
      <img src="@/assets/img/LongRent/banner.png"
           alt="">
    </div>
    <div class="demand">
      <h2>满足多场景租车需求</h2>
      <div class="demand_con">
        <div v-for="(item,index) in demandArr"
             :key="index"
             :class="`demand_con_item ${index % 2 === 0 ? 'left' : 'right'}`">
          <div class="item_div">
            <img :src="item.img"
                 :alt="item.h">
          </div>
          <div class="item_div">
            <div class="item_introduce">
              <div class="item_title">{{item.h}}</div>
              <div class="item_text">{{item.text}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="service">
      <h2>服务与保障</h2>
      <div class="service_con">
        <div class="service_list">
          <div class="service_item"
               v-for="(item,index) in serviceArr"
               :key="index">
            <img :src="item.src"
                 alt=""
                 class="item_img">
            <div class="item_count">{{item.count}}</div>
            <div class="item_type">{{item.type}}</div>
          </div>
        </div>
        <div class="service_button"
             @click="commitkefu">立即咨询</div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data () {
    return {
      demandArr: [
        { img: require('@/assets/img/LongRent/pic1.png'), h: '价格更优惠', text: '车辆长租服务价格更加优惠，支持分期支付，轻松租赁，放心使用。' },
        { img: require('@/assets/img/LongRent/pic2.png'), h: '租期更灵活', text: '满足不同时长与时段的用车需求，XX天~XXX天套餐保障您出行所需。' },
        { img: require('@/assets/img/LongRent/pic3.png'), h: '专业服务升级', text: '售后服务，发票服务，司机配驾等服务，一站式升级。' }
      ],
      serviceArr: [
        { src: require('@/assets/img/LongRent/chexing.png'), count: '100+', type: '车型' },
        { src: require('@/assets/img/LongRent/siji.png'), count: '100+', type: '专业司机' },
        { src: require('@/assets/img/LongRent/shouhou.png'), count: '100%', type: '售后服务' }
      ]
    }
  },
  components: {

  },
  methods: {
    commitkefu () {
      this.Bus.$emit('kefu', '111')
    },
  },
}
</script>
<style lang="scss" scoped>
.long_rent {
  min-width: 1200px;
  & > div {
    margin: 0 auto 80px;
    text-align: center;
  }
  .cover_img {
    position: relative;
    width: 100%;
    img {
      width: 100%;
    }
  }
  .demand {
    width: 1200px;
    h2 {
      margin-bottom: 60px;
      height: 34px;
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 19px;
    }
    .demand_con {
      width: 100%;
      .demand_con_item {
        display: flex;
        .item_div {
          width: 600px;
          height: 340px;
          position: relative;
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }
          .item_introduce {
            margin: 117px 0 0 100px;
            text-align: left;
            .item_title {
              height: 29px;
              font-size: 30px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #333333;
              // line-height: 19px;
            }
            .item_text {
              margin-top: 15px;
              width: 310px;
              // white-space:nowrap;
              font-size: 18px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 30px;
            }
          }
        }
      }
      .left {
        flex-direction: row;
      }
      .right {
        flex-direction: row-reverse;
      }
    }
  }
  .service {
    width: 1200px;
    h2 {
      margin-bottom: 60px;
      height: 34px;
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 19px;
    }
    .service_con {
      width: 100%;
      .service_list {
        width: 100%;
        display: flex;
        justify-content: space-around;
        .service_item {
          width: 250px;
          height: 176px;
          background: #2a62ae;
          color: #fff;
          position: relative;
          .item_img {
            position: absolute;
            width: 52px;
            height: 52px;
            left: 50%;
            top: 0%;
            transform: translate(-50%, -50%);
          }
          .item_count {
            margin-top: 53px;
            font-size: 52px;
            line-height: 52px;
            font-family: DIN;
            font-weight: bold;
          }
          .item_type {
            margin-top: 19px;
            font-size: 24px;
            line-height: 24px;
            font-family: PingFang SC;
            font-weight: 400;
          }
        }
      }
      .service_button {
        display: inline-block;
        margin-top: 60px;
        padding: 16px 62px;
        border: 1px solid #2a62ae;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #2a62ae;
        line-height: 30px;
        cursor: pointer;
      }
    }
  }
}
</style>


