<template >
  <div class="all">
    <div class="to_rent">
      <!-- 标签头 -->
      <div class="tabs">
        <div class="tabs_list">
          <div v-for="(item,index) in tabs" :key="index" @click="clickTab(item.index)" :class="`tab_item ${activeTab === item.index ? 'tab_active' : ''}`">{{item.label}}</div>
        </div>
      </div>

      <!-- 标签内容 -->
      <div v-if="activeItem !== ''" class="jianjie">
        <div class="jj_title">{{activeItem.title}}</div>
        <div class="jj_time">{{activeItem.time}}</div>
        <div class="jj_p" v-html="activeItem.p"></div>
      </div>
      <div v-else class="wenzhang">
        <div class="wz_list">
          <div v-for="(item,index) in current" :key="index" class="wz_item" @click="wzClick(item)">
            <div class="item_left">
              <div class="left_day">{{item.day}}</div>
              <div class="left_month">{{item.yearMonth}}</div>
            </div>
            <div class="item_right">
              <div class="right_title">{{item.title}}</div>
              <div class="right_p" v-html="item.p"></div>
            </div>
          </div>
        </div>
        <div class="wz_page">
          <div class="page_count">共{{total}}条数据</div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" background :page-sizes="[5, 10, 20, 50]" :page-size="5" :pager-count="5" layout="prev, pager, next, sizes, jumper" :total="total">
          </el-pagination>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      activeTab: 0,
      activeItem: { title: '文章标题', time: '2021-11-22 11:52', p: "<p>合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总部位于上海。公司的核心业务是为客户提供高性价比的IT集成服务、软件服务以及外包服务的整体服务解决方案。公司通过了ISO9001:2000质量管理体系认证、计算机系统集成三级认证、上海市双软企业、高新技术企业等资质认证的认定，以极具竞争力的IT综合服务及支持能力、客户第一的服务理念为客户创造价值。在此同时合胜科技还获得上海最具活力科技企业等荣誉称号。</p><br/><p>合胜科技坚持'诚敬创新、合作共赢'的企业理念，与客户共成长，先后在北京、广州、成都、深圳、苏州、杭州等地设立了分公司和办事处，建设了完备的服务网络，正逐步成长为中国IT服务行业的全国品牌。合胜科技同厂商的合作也不断加强，取得了Cisco银牌代理商、IBM解决方案提供商、BMC解决方案和服务提供商、安氏领信南方区域总代理、华为服务五钻合作伙伴等各种厂商资质。</p><br/><p>合胜科技具有很强的自我完善和自我学习的能力，结合中国国内的实际环境，总结出一套完整的服务流程、知识管理、项目管理等创新高效的管理方法，可以极大地提高团队的工作效率和服务质量。合胜科技众多成功的项目经验验证并更加完善了客户服务管理方法，并以我们出众的服务质量在客户群中赢得了一致的口碑与源源不断的合作机会。在为客户提供优质服务的同时，我们也注重公司核心竞争力的建设，在自主知识产权和创新方面取得了阶段性的突破，拥有金融、零售等行业解决方案的多个产品专利和软件著作权。</p>" },
      tabs: [{ label: '公司简介', index: 0 }, { label: '公司动态', index: 1 }, { label: '优惠活动', index: 2 }, { label: '租车攻略', index: 3 }],
      current: [
        { yearMonth: '2021-11-22', title: '文章标题', time: '2021-11-22 11:52', p: "<p>合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总部位于上海。公司的核心业务是为客户提供高性价比的IT集成服务、软件服务以及外包服务的整体服务解决方案。公司通过了ISO9001:2000质量管理体系认证、计算机系统集成三级认证、上海市双软企业、高新技术企业等资质认证的认定，以极具竞争力的IT综合服务及支持能力、客户第一的服务理念为客户创造价值。在此同时合胜科技还获得上海最具活力科技企业等荣誉称号。</p><br/><p>合胜科技坚持'诚敬创新、合作共赢'的企业理念，与客户共成长，先后在北京、广州、成都、深圳、苏州、杭州等地设立了分公司和办事处，建设了完备的服务网络，正逐步成长为中国IT服务行业的全国品牌。合胜科技同厂商的合作也不断加强，取得了Cisco银牌代理商、IBM解决方案提供商、BMC解决方案和服务提供商、安氏领信南方区域总代理、华为服务五钻合作伙伴等各种厂商资质。</p><br/><p>合胜科技具有很强的自我完善和自我学习的能力，结合中国国内的实际环境，总结出一套完整的服务流程、知识管理、项目管理等创新高效的管理方法，可以极大地提高团队的工作效率和服务质量。合胜科技众多成功的项目经验验证并更加完善了客户服务管理方法，并以我们出众的服务质量在客户群中赢得了一致的口碑与源源不断的合作机会。在为客户提供优质服务的同时，我们也注重公司核心竞争力的建设，在自主知识产权和创新方面取得了阶段性的突破，拥有金融、零售等行业解决方案的多个产品专利和软件著作权。</p>" },
        { yearMonth: '2021-10-23', title: '文章标题', time: '2021-11-22 11:52', p: "<p>合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总部位于上海。公司的核心业务是为客户提供高性价比的IT集成服务、软件服务以及外包服务的整体服务解决方案。公司通过了ISO9001:2000质量管理体系认证、计算机系统集成三级认证、上海市双软企业、高新技术企业等资质认证的认定，以极具竞争力的IT综合服务及支持能力、客户第一的服务理念为客户创造价值。在此同时合胜科技还获得上海最具活力科技企业等荣誉称号。</p><br/><p>合胜科技坚持'诚敬创新、合作共赢'的企业理念，与客户共成长，先后在北京、广州、成都、深圳、苏州、杭州等地设立了分公司和办事处，建设了完备的服务网络，正逐步成长为中国IT服务行业的全国品牌。合胜科技同厂商的合作也不断加强，取得了Cisco银牌代理商、IBM解决方案提供商、BMC解决方案和服务提供商、安氏领信南方区域总代理、华为服务五钻合作伙伴等各种厂商资质。</p><br/><p>合胜科技具有很强的自我完善和自我学习的能力，结合中国国内的实际环境，总结出一套完整的服务流程、知识管理、项目管理等创新高效的管理方法，可以极大地提高团队的工作效率和服务质量。合胜科技众多成功的项目经验验证并更加完善了客户服务管理方法，并以我们出众的服务质量在客户群中赢得了一致的口碑与源源不断的合作机会。在为客户提供优质服务的同时，我们也注重公司核心竞争力的建设，在自主知识产权和创新方面取得了阶段性的突破，拥有金融、零售等行业解决方案的多个产品专利和软件著作权。</p>" },
        { yearMonth: '2021-08-12', title: '文章标题', time: '2021-11-22 11:52', p: "<p>合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总部位于上海。公司的核心业务是为客户提供高性价比的IT集成服务、软件服务以及外包服务的整体服务解决方案。公司通过了ISO9001:2000质量管理体系认证、计算机系统集成三级认证、上海市双软企业、高新技术企业等资质认证的认定，以极具竞争力的IT综合服务及支持能力、客户第一的服务理念为客户创造价值。在此同时合胜科技还获得上海最具活力科技企业等荣誉称号。</p><br/><p>合胜科技坚持'诚敬创新、合作共赢'的企业理念，与客户共成长，先后在北京、广州、成都、深圳、苏州、杭州等地设立了分公司和办事处，建设了完备的服务网络，正逐步成长为中国IT服务行业的全国品牌。合胜科技同厂商的合作也不断加强，取得了Cisco银牌代理商、IBM解决方案提供商、BMC解决方案和服务提供商、安氏领信南方区域总代理、华为服务五钻合作伙伴等各种厂商资质。</p><br/><p>合胜科技具有很强的自我完善和自我学习的能力，结合中国国内的实际环境，总结出一套完整的服务流程、知识管理、项目管理等创新高效的管理方法，可以极大地提高团队的工作效率和服务质量。合胜科技众多成功的项目经验验证并更加完善了客户服务管理方法，并以我们出众的服务质量在客户群中赢得了一致的口碑与源源不断的合作机会。在为客户提供优质服务的同时，我们也注重公司核心竞争力的建设，在自主知识产权和创新方面取得了阶段性的突破，拥有金融、零售等行业解决方案的多个产品专利和软件著作权。</p>" },
        { yearMonth: '2021-01-22', title: '文章标题', time: '2021-11-22 11:52', p: "<p>合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总部位于上海。公司的核心业务是为客户提供高性价比的IT集成服务、软件服务以及外包服务的整体服务解决方案。公司通过了ISO9001:2000质量管理体系认证、计算机系统集成三级认证、上海市双软企业、高新技术企业等资质认证的认定，以极具竞争力的IT综合服务及支持能力、客户第一的服务理念为客户创造价值。在此同时合胜科技还获得上海最具活力科技企业等荣誉称号。</p><br/><p>合胜科技坚持'诚敬创新、合作共赢'的企业理念，与客户共成长，先后在北京、广州、成都、深圳、苏州、杭州等地设立了分公司和办事处，建设了完备的服务网络，正逐步成长为中国IT服务行业的全国品牌。合胜科技同厂商的合作也不断加强，取得了Cisco银牌代理商、IBM解决方案提供商、BMC解决方案和服务提供商、安氏领信南方区域总代理、华为服务五钻合作伙伴等各种厂商资质。</p><br/><p>合胜科技具有很强的自我完善和自我学习的能力，结合中国国内的实际环境，总结出一套完整的服务流程、知识管理、项目管理等创新高效的管理方法，可以极大地提高团队的工作效率和服务质量。合胜科技众多成功的项目经验验证并更加完善了客户服务管理方法，并以我们出众的服务质量在客户群中赢得了一致的口碑与源源不断的合作机会。在为客户提供优质服务的同时，我们也注重公司核心竞争力的建设，在自主知识产权和创新方面取得了阶段性的突破，拥有金融、零售等行业解决方案的多个产品专利和软件著作权。</p>" },
        { yearMonth: '2021-01-02', title: '文章标题', time: '2021-11-22 11:52', p: "<p>合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总部位于上海。公司的核心业务是为客户提供高性价比的IT集成服务、软件服务以及外包服务的整体服务解决方案。公司通过了ISO9001:2000质量管理体系认证、计算机系统集成三级认证、上海市双软企业、高新技术企业等资质认证的认定，以极具竞争力的IT综合服务及支持能力、客户第一的服务理念为客户创造价值。在此同时合胜科技还获得上海最具活力科技企业等荣誉称号。</p><br/><p>合胜科技坚持'诚敬创新、合作共赢'的企业理念，与客户共成长，先后在北京、广州、成都、深圳、苏州、杭州等地设立了分公司和办事处，建设了完备的服务网络，正逐步成长为中国IT服务行业的全国品牌。合胜科技同厂商的合作也不断加强，取得了Cisco银牌代理商、IBM解决方案提供商、BMC解决方案和服务提供商、安氏领信南方区域总代理、华为服务五钻合作伙伴等各种厂商资质。</p><br/><p>合胜科技具有很强的自我完善和自我学习的能力，结合中国国内的实际环境，总结出一套完整的服务流程、知识管理、项目管理等创新高效的管理方法，可以极大地提高团队的工作效率和服务质量。合胜科技众多成功的项目经验验证并更加完善了客户服务管理方法，并以我们出众的服务质量在客户群中赢得了一致的口碑与源源不断的合作机会。在为客户提供优质服务的同时，我们也注重公司核心竞争力的建设，在自主知识产权和创新方面取得了阶段性的突破，拥有金融、零售等行业解决方案的多个产品专利和软件著作权。</p>" },
      ],
      wayId: '',
      pageNum: 1,
      pageSize: 8,
      total:0
    }
  },
  components: {

  },
  computed:{
    ...mapState(['websiteCompanyDetail'])
  },
  mounted() {
    let { index } = this.$route.params
    this.activeTab = index || 0;  
    // isDetail === 2 && (this.activeItem = '')
    (async (index_) => {
      window.localStorage.clear();
      let res = await this.$http.websiteCompanyDetail({})
      if (res.code != 0) {
        let json=res.body
        this.activeItem= {p:json.companyDetail,time:json.createTime,title:json.companyName}
        window.localStorage.setItem('wayId', res.body.wayId)
        this.wayId = res.body.wayId
        window.localStorage.setItem('websiteCompanyDetail', JSON.stringify(res.body))
      
        this.asyncWebsiteCompanyDetail(res.body)
        this.clickTab(index_, this.wayId)
      }
    })(this.activeTab)
  },
  methods: {
    ...mapActions(['asyncWebsiteCompanyDetail']),
    clickTab(text = this.activeTab, wayId = this.wayId) {
      this.activeTab = text;
      let postData = {
        1: 'clientCompanyArticleList',
        2: 'websiteCompanyActivityList',
        3: 'websiteCompanyStrategyList'
      }
      if (text == 0) {
        let json=this.websiteCompanyDetail
        this.activeItem={p:json.companyDetail,time:json.createTime,title:json.companyName}
        return false 
      }
      this.$http[(postData[text])]({ wayId: wayId, "pageNum": this.pageNum, "pageSize": this.pageSize, })
        .then(res => {
          console.log(res)
          this.current = res.body.map(item => {
            // console.log('____', item)
            let date = item.createTime.split(' ')[0]
            item.title = item.articleTitle || item.activityTitle
            item.date = item.createTime.split(' ')[1]
            item.year = item.createTime.split(' ')[0]
            item.yearMonth = date.slice(0, 7)
            item.day = date.slice(8, 10)
            item.photo = window.baseUrl + item.cover
            item.p = item.acticleContent || item.activityContent
            return item
          })
          this.total=res.total
        })


      text === '公司简介' ? this.activeItem = this.current[0] : this.activeItem = ''
    },
    wzClick(item) {

      let paramsUrl = {
        1: 'clientCompanyArticle',
        2: 'websiteCompanyActivity',
        3: 'websiteCompanyStrategy'
      }
      this.$http[(paramsUrl[this.activeTab])]({ wayId: this.wayId, caId: item.caId })
        .then(res => {
          let json=res.body
          this.activeItem = res.body;
          this.activeItem.title = json.articleTitle || json.activityTitle
          this.activeItem.p = json.acticleContent || json.activityContent
          this.activeItem.time=json.createTime    
        })
    },
    handleSizeChange(size) {

      this.pageSize = size
      this.clickTab()
      // console.log(e)
    },
    handleCurrentChange(num) {
      this.pageNum = num
      this.clickTab()
      // console.log(e)
    }
  },
}
</script>
<style lang="scss" scope>
.all {
  width: 100%;
  height: 100%;
  padding: 20px 0;
  background-color: #f9f9f9;
}
.to_rent {
  margin: auto;
  width: 1200px;
  display: flex;
}
.tabs {
  margin-right: 20px;
  .tabs_list {
    background-color: #fff;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .tab_item {
      font-size: 16px;
      font-weight: 500;
      padding: 15px 33px;
      cursor: pointer;
      white-space: nowrap;
    }
    .tab_active {
      color: #fabe00;
    }
  }
}
.jianjie {
  width: 974px;
  height: 658px;
  padding: 30px 38px;
  background-color: #fff;
  text-align: center;
  .jj_title {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .jj_time {
    margin: 13px 0 26px 0;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  .jj_p {
    text-indent: 2em;
    text-align: left;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 21px;
  }
}

.wenzhang {
  // margin-bottom: 113px;
  .wz_list {
    .wz_item {
      background-color: #fff;
      padding: 14px 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .item_left {
        text-align: center;
        padding-right: 20px;
        border-right: 1px solid #eee;
        color: #999;
        .left_day {
          font-size: 36px;
          font-family: DINPro;
          font-weight: 500;
        }
        .left_month {
          font-size: 14px;
          font-weight: 400;
          white-space: nowrap;
        }
      }
      .item_right {
        padding-left: 20px;
        .right_title {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-bottom: 12px;
          font-weight: 600;
        }
        .right_p {
          // width: 914px;
          height: 42px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 21px;
          display: -webkit-box; /**对象作为伸缩盒子模型展示**/
          -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
          -webkit-line-clamp: 2; /**显示的行数**/
          overflow: hidden; /**隐藏超出的内容**/
        }
      }
    }
  }
  .wz_page {
    margin-top: 30px;
    display: flex;
    justify-content: right;
    align-items: center;
  }
}

// 设置分页器样式
.wz_page {
  .el-pagination.is-background {
    .btn-next,
    .btn-prev {
      background-color: #fff;
      border: 1px solid #cccccc;
      border-radius: 4px;
    }
    .el-pager li:not(.disabled) {
      background-color: #fff; // 进行修改未选中背景和字体
      color: #ccc;
      border: 1px solid #cccccc;
      border-radius: 4px;
    }
    .el-pager li:not(.disabled).active {
      background-color: #323f5c; // 进行修改选中项背景和字体
      color: #fff;
      border: 1px solid #323f5c;
      border-radius: 4px;
    }
  }
}
</style>

