import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
 
// 用来存储数据
const state = {
  websiteCompanyDetail:{}//公司详情
}
// 响应组件中的事件
const actions = {
  asyncWebsiteCompanyDetail(context,data){
    context.commit('setWebsiteCompanyDetail',data)
  }
}
// 操作数据
const mutations = {
  setWebsiteCompanyDetail(state,data){
    state.websiteCompanyDetail=data
  }
}
// 用来将state数据进行加工
const getters = {
 
}
// 新建并暴露store
export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters

})