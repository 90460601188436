<template>
  <div class="header">
    <span class="headerLogo">
      <img :src="headerLogo" alt="探途" width="40px" height="40px">
    </span>
    <ul>
      <li @click="chectout('index')">首页</li>
      <li @click="chectout('longRent')">长租</li>
      <li @click="chectout('rentCar')">我要租车</li>
    </ul>
    <div class="phone">
      {{companyCall}}
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      headerLogo: require('@/assets/img/logo_1.png'),
      companyCall: '18718873370'
    }
  },
  components: {

  },
  mounted() {
    setTimeout(() => {
      let company = JSON.parse(window.localStorage.getItem('websiteCompanyDetail'))
      if (company) {
        this.headerLogo = window.baseUrl + company.companyImg
        this.companyCall = company.companyCall
        document.title = company.companyName

        const link = document.createElement("link"); //先获取link标签对象
        link.rel = "icon"; //设置rel类型
        link.href = this.headerLogo + '';
        document.head.appendChild(link); //输出修改

      } else {
        this.headerLogo = require('@/assets/img/logo_1.png')
      }
    }, 1000)



  },
  methods: {
    chectout(path) {
      if (this.$router.currentRoute.name !== path) {
        this.$router.push({ name: path })
      }
    },
  },
}
</script>
<style lang="scss">
.header {
  display: flex;
  padding: 20px 0;
  .headerLogo {
    float: left;
    height: 32px;
    line-height: 32px;
  }
  ul {
    margin: auto;
    height: 32px;
    line-height: 32px;
  }
  ul li {
    display: inline-block;
    font-size: 16px;
    color: #111;
    margin-right: 80px;
    cursor: pointer;
  }
  ul li:hover {
    color: #226aff;
  }
  .phone {
    height: 32px;
    line-height: 32px;
    padding-left: 34px;
    float: right;
    background: url("~@/assets/img/phone.png") no-repeat center left;
  }
}

.tabsActive {
  // color: #226aff !important;
}
</style>


