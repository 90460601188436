<template>
  <div class="main">
    <div class="banner">
      <Swipper :imgArr="imgArr" />
    </div>
    <div class="content">
      <div class="one">
        <h2>热门车型·精选热门出租车型</h2>
        <div class="con">
          <div class="check" @click="toRentCar(1)">查看所有车型</div>
          <div class="car_model">
            <div class="item" v-for="item in carSort" :key="item.id">
              <div class="item_img">
                <img :src="item.src" width="100%" height="100%" :alt="item.name">
              </div>
              <div class="item_text">
                <div class="item_name">{{item.name}}</div>
                <div class="item_cs">{{item.engine+' | '+item.layout}}</div>
                <div class="item_botton" @click="toRentCar(item)">立即查看</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="two">
        <h2>出行方案·满足多场景租车需求</h2>
        <div class="con">
          <div class="left father">
            <img :src="require('@/assets/img/pic1.png')" alt="">
            <div class="child">
              <div>自驾旅行</div>
              <div class="button" @click="commitkefu()">立即咨询</div>
            </div>
          </div>
          <div class="right">
            <div class="right_item father" v-for="(item,index) in ideas" :key="index">
              <img :src="item.url" alt="">
              <div class="child">
                <div>{{item.text}}</div>
                <div class="button" @click="commitkefu()">立即咨询</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="three">
        <h2>租车F&Q·常见问题答疑</h2>
        <div class="con">
          <div class="three_item" v-for="(item,index) in questions" :key="index">
            <div class="title"><span class="count">Q{{index+1}}</span>{{item.title}}</div>
            <div class="answer" v-html="item.answer"></div>
          </div>
        </div>
      </div>
      <div class="four">
        <h2>关于我们·一起见证成长</h2>
        <div class="con">
          <div class="title">
            <div @click="changeTab(aboutUs.title)" :style="{'border-color': activeTab === aboutUs.title ? '#fabe00' : '#eee'}">{{aboutUs.title}}</div>
            <div v-for="(item,index) in aboutArr" :key="index" @click="changeTab(item.title)" :style="{'border-color': activeTab === item.title ? '#fabe00' : '#eee'}">{{item.title}}</div>
          </div>
          <div class="four_content">
            <div :style="{'display': activeTab === aboutUs.title ? 'flex' : 'none'}" class="intro">
              <div class="img">
                <img :src="aboutUs.img" alt="公司图片" width="340px">
              </div>
              <div class="html" v-html="aboutUs.html"></div>
              <div class="more" @click="toDetail(0)">查看更多</div>
            </div>
            <div v-for="(item,index) in aboutArr" :key="index" :style="{'display': activeTab === item.title  ? 'flex' : 'none'}" class="content_item">
              <div v-for="(i,index_) in item.arr" :key="'child'+index_" class="item_div" @click="toDetail(index+1)">
                <div class="item_div_img"><img width="100%" :src="i.photo" alt=""></div>
                <div class="item_div_right">
                  <div class="right_time">
                    <div class="right_year">{{i.year+'-'+i.date}}</div>
                    <!-- <div class="right_date">{{}}
                      <div class="slash"></div>
                    </div> -->
                  </div>  
                  <div class="right_content" style="width: 350px;">
                    <h6>{{i.item_title}}</h6>
                    <p v-html="i.content"></p>
                  </div>
                </div>
              </div>
              <div class="more" @click="toDetail(index+1)">查看更多</div>
            </div>
          </div>
        </div>
      </div>
      <div class="five">
        <h2>合作伙伴·互助共赢</h2>
        <div class="con">
          <div v-for="(item,index) in partners" :key="index" class="five_item">
            <img :src="item.src" :alt="item.title" :title="item.title" height="100%">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Swipper from '@/components/common/Swipper.vue'
import {
  // mapState,
  mapActions
} from 'vuex'
export default {
  data() {
    return {
      imgArr: [
        {
          id: 1,
          alt: '一',
          src: require('@/assets/img/banner.png'),
        },
        {
          id: 2,
          alt: '二',
          src: require('@/assets/img/banner.png'),
        },
        {
          id: 3,
          alt: '三',
          src: require('@/assets/img/banner.png'),
        },
      ],
      carSort: [
        {
          id: 1,
          src: require('@/assets/img/pic-weixuanzhong.png'),
          name: '2021大众帕萨特',
          engine: '1.4T自动',
          layout: '三厢五座',
        },
        {
          id: 2,
          src: require('@/assets/img/pic-weixuanzhong.png'),
          name: '2021大众帕萨特',
          engine: '1.4T自动',
          layout: '三厢五座',
        },
        {
          id: 3,
          src: require('@/assets/img/pic-weixuanzhong.png'),
          name: '2021大众帕萨特',
          engine: '1.4T自动',
          layout: '三厢五座',
        },
        {
          id: 4,
          src: require('@/assets/img/pic-weixuanzhong.png'),
          name: '2021大众帕萨特',
          engine: '1.4T自动',
          layout: '三厢五座',
        }
      ],
      ideas: [
        { text: '商务接送', url: require('@/assets/img/pic2.png') },
        { text: '组团出行', url: require('@/assets/img/pic3.png') },
        { text: '长途旅游', url: require('@/assets/img/pic4.png') },
        { text: '日常通勤', url: require('@/assets/img/pic5.png') }
      ],
      questions: [
        { title: ' 租车需要哪些证件？', answer: "身份证：大陆用户驾驶员二代身份证，年满18周岁，有效期需 超过当次租期一个月以上。<br/>驾驶证：本人国内有效驾驶证（正副本），有效期需超过当次租期一个月以上。<br/>港澳台客户准备澳港内地通行证或台湾居民来往大陆通行证，有效期超过当次租期两个月以上。<br/>外籍客户提供护照，有效期超过当次租期两个月以上。" },
        { title: ' 租车需要哪些证件？', answer: "国内租订单还车结算后以订单为单位，为您开具增值税发票；支持电子发票和纸质发票。" },
        { title: ' 租车需要哪些证件？', answer: "必缴费用包括：车辆租赁及服务费、基础服务费、车辆整备费共三项。<br/>其他费用包括：送车上门服务费、上门取车服务费、跨网点还车服务费、异地还车服务费、加油服务费、超时服务费等。" },
        { title: ' 租车需要哪些证件？', answer: "只需3步即可完成预定。<br/>第1步：确定需要取还的地址和时间。<br/>第2步：确定车型和乘车人。<br/>第3步：订单确认，完成预定。" }
      ],
      aboutUs: { title: '公司简介', img: require('@/assets/img/gognsijianjie-pic.png'), html: "合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总部位于上海。公司的核心业务是为客户提供高性价比的IT集成服务、软件服务以及外包服务的整体服务解决方案。公司通过了ISO9001:2000质量管理体系认证、计算机系统集成三级认证、上海市双软企业、高新技术企业等资质认证的认定，以极具竞争力的IT综合服务及支持能力、客户第一的服务理念为客户创造价值。在此同时合胜科技还获得上海最具活力科技企业等荣誉称号。<br/><br/>合胜科技坚持'诚敬创新、合作共赢'的企业理念，与客户共成长，先后在北京、广州、成都、深圳、苏州、杭州等地设立了分公司和办事处，建设了完备的服务网络，正逐步成长为中国IT服务行业的全国品牌。合胜科技同厂商的合作也不断加强，取得了Cisco银牌代理商、IBM解决方案提供商、BMC解决方案和服务提供商、安氏领信南方区域总代理、华为服务五钻合作伙伴等各种厂商资质。<br/><br/>合胜科技具有很强的自我完善和自我学习的能力，结合中国国内的实际环境，总结出一套完整的服务流程、知识管理、项目管理等创新高效的管理方法，可以极大地提高团队的工作效率和服务质量。合胜科技众多成功的项目经验验证并更加完善了客户服务管理方法，并以我们出众的服务质量在客户群中赢得了一致的口碑与源源不断的合作机会。在为客户提供优质服务的同时，我们也注重公司核心竞争力的建设，在自主知识产权和创新方面取得了阶段性的突破，拥有金融、零售等行业解决方案的多个产品..." },
      aboutArr: [
        {
          title: '公司动态',
          arr: [
            // {
            //   item_title: '标题',
            //   photo: require('@/assets/img/pho1.png'),
            //   year: '2021',
            //   date: '12-9',
            //   content: '合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总上海。公司的核心业务是为客户提供高性价比的整合部上海合胜科技致力于成为中国一流的专业化、个，总上海业化业化...'
            // },
            // {
            //   item_title: '标题',
            //   photo: require('@/assets/img/pho2.png'),
            //   year: '2021',
            //   date: '12-9',
            //   content: '合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总上海。公司的核心业务是为客户提供高性价比的整合部上海合胜科技致力于成为中国一流的专业化、个，总上海业化业化...'
            // },
            // {
            //   item_title: '标题',
            //   photo: require('@/assets/img/pho3.png'),
            //   year: '2021',
            //   date: '12-9',
            //   content: '合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总上海。公司的核心业务是为客户提供高性价比的整合部上海合胜科技致力于成为中国一流的专业化、个，总上海业化业化...'
            // },
            // {
            //   item_title: '标题',
            //   photo: require('@/assets/img/pho4.png'),
            //   year: '2021',
            //   date: '12-9',
            //   content: '合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总上海。公司的核心业务是为客户提供高性价比的整合部上海合胜科技致力于成为中国一流的专业化、个，总上海业化业化...'
            // }
          ]
        },
        {
          title: '优惠活动',
          arr: [
            {
              item_title: '标题',
              photo: require('@/assets/img/pho1.png'),
              year: '2021',
              date: '12-9',
              content: '合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总上海。公司的核心业务是为客户提供高性价比的整合部上海合胜科技致力于成为中国一流的专业化、个，总上海业化业化...'
            },
            {
              item_title: '标题',
              photo: require('@/assets/img/pho2.png'),
              year: '2021',
              date: '12-9',
              content: '合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总上海。公司的核心业务是为客户提供高性价比的整合部上海合胜科技致力于成为中国一流的专业化、个，总上海业化业化...'
            },
            {
              item_title: '标题',
              photo: require('@/assets/img/pho3.png'),
              year: '2021',
              date: '12-9',
              content: '合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总上海。公司的核心业务是为客户提供高性价比的整合部上海合胜科技致力于成为中国一流的专业化、个，总上海业化业化...'
            },
            {
              item_title: '标题',
              photo: require('@/assets/img/pho4.png'),
              year: '2021',
              date: '12-9',
              content: '合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总上海。公司的核心业务是为客户提供高性价比的整合部上海合胜科技致力于成为中国一流的专业化、个，总上海业化业化...'
            }
          ]
        },
        {
          title: '租车攻略',
          arr: [
            {
              item_title: '标题',
              photo: require('@/assets/img/pho1.png'),
              year: '2021',
              date: '12-9',
              content: '合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总上海。公司的核心业务是为客户提供高性价比的整合部上海合胜科技致力于成为中国一流的专业化、个，总上海业化业化...'
            },
            {
              item_title: '标题',
              photo: require('@/assets/img/pho2.png'),
              year: '2021',
              date: '12-9',
              content: '合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总上海。公司的核心业务是为客户提供高性价比的整合部上海合胜科技致力于成为中国一流的专业化、个，总上海业化业化...'
            },
            {
              item_title: '标题',
              photo: require('@/assets/img/pho3.png'),
              year: '2021',
              date: '12-9',
              content: '合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总上海。公司的核心业务是为客户提供高性价比的整合部上海合胜科技致力于成为中国一流的专业化、个，总上海业化业化...'
            },
            {
              item_title: '标题',
              photo: require('@/assets/img/pho4.png'),
              year: '2021',
              date: '12-9',
              content: '合胜科技致力于成为中国一流的专业化、个性化的IT服务企业，总上海。公司的核心业务是为客户提供高性价比的整合部上海合胜科技致力于成为中国一流的专业化、个，总上海业化业化...'
            }
          ]
        }
      ],
      partners: [
        { src: require('@/assets/img/logo1.png') },
        { src: require('@/assets/img/logo2.png') },
        { src: require('@/assets/img/logo3.png') },
        { src: require('@/assets/img/logo4.png') },
        { src: require('@/assets/img/logo5.png') },
        { src: require('@/assets/img/logo6.png') },
        { src: require('@/assets/img/logo7.png') },
        { src: require('@/assets/img/logo8.png') },

      ],
      activeTab: '公司简介',
      dialogVisible: false
    }
  },
  // inject:['root'],
  mounted() {
    console.log(this.asyncWebsiteCompanyDetail());
    (async () => {
      localStorage.clear();
      let res = await this.$http.websiteCompanyDetail({})
      if (res.code != 0) {

        window.localStorage.setItem('wayId', res.body.wayId)
        window.localStorage.setItem('websiteCompanyDetail', JSON.stringify(res.body))
        this.asyncWebsiteCompanyDetail(res.body)
        let params = { wayId: localStorage.getItem('wayId') }
        // 问答
        this.$http.clientQueryFaqList(params)
          .then(res => {
            console.log(res)
            this.questions = res.body.rentalFaqList.map(item => {
              item.title = item.faqQuestion
              item.answer = item.faqAsk
              return item
            })
          })
        this.$http.clientQueryRotateImgList(params)
          .then(res => {
            console.log(res)
            this.imgArr = res.body.rotateImgList.map(item => {
              item.id = item.riId
              item.src = window.baseUrl + item.riUrl
              return item
            })
          })
        this.$http.clientQueryCompanyDetail(params)
          .then(res => {
            this.aboutUs.html = res.body.companyDetail
            this.aboutUs.img = window.baseUrl + res.body.companyImg
          })
        this.$http.clientQueryCooperatives(params)
          .then(res => {
            console.log(res)
            this.partners = res.body.map(item => {
              return {
                title: item.partnerName,
                src: window.baseUrl + item.partnerUrl
              }
            })
          })
        this.$http.clientQueryModelSettingList(params)
          .then(res => {
            if (res.body && res.body.length) {
              let list = res.body.map(item => {
                return {
                  id: item.msId,
                  src: window.baseUrl + item.mainImage,
                  name: item.shapeVal + item.seriesName,
                  engine: item.carLabelVal,
                  layout: item.carDescribe,
                  rentFee: item.rentFee
                }
              })
              this.carSort = list.slice(0, 4)
            }
          })
        //        2: 'websiteCompanyActivityList',
        //3: 'websiteCompanyStrategyList'

        this.$http.websiteCompanyActivityList(params)
          .then(res => {
            let json = res.body.map(item => {
              item.item_title = item.activityTitle
              item.date = item.createTime.split(' ')[1]
              item.year = item.createTime.split(' ')[0]
              item.photo = window.baseUrl + item.cover
              item.content = item.activityContent
              return item
            })
            this.aboutArr[1].arr = json.slice(0, 4)
          })
        this.$http.websiteCompanyStrategyList(params)
          .then(res => {
            if (res.body&&res.body.length) {
              let json=res.body.map(item => {
                item.item_title = item.activityTitle
                item.date = item.createTime.split(' ')[1]
                item.year = item.createTime.split(' ')[0]
                item.photo = window.baseUrl + item.cover
                item.content = item.activityContent
                return item
              })
              this.aboutArr[2].arr = json.slice(0, 4)
            }

          })
        this.$http.clientCompanyArticleList(params)
          .then(res => {
            if (res.body&&res.body.length) {
              let json=res.body.map(item => {
                item.item_title = item.articleTitle
                item.date = item.createTime.split(' ')[1]
                item.year = item.createTime.split(' ')[0]
                item.photo = window.baseUrl + item.cover
                item.content = item.acticleContent
                return item
              })
              this.aboutArr[0].arr =json.slice(0, 4)
            }  
          })
      }
    })()
  },
  methods: {
    ...mapActions(['asyncWebsiteCompanyDetail']),
    changeTab(conf) {
      if (this.activeTab !== conf) {
        this.activeTab = conf
      }
    },
    toDetail(index) {
      let params = { activeTab: this.activeTab, index: index }
      this.$router.push({ name: 'detail', params })
    },
    toRentCar(detail) {
      let params = { isDetail: detail,detail }
      this.$router.push({ name: 'rentCar', params })
    },
    commitkefu() {
      this.Bus.$emit('kefu', '111')
    }
  },
  components: {
    Swipper
  }
}
</script>
<style lang="scss" scoped>
.main {
  min-width: 1200px;
  .content {
    color: #333333;
    div > h2 {
      text-align: center;
      margin-bottom: 60px;
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: bold;
    }
    & > div {
      margin: 0 auto;
      padding: 80px 0;
      background-color: #fff;
      .con {
        margin: auto;
        width: 1200px;
      }
    }
    .one {
      width: 100%;
      background-color: #323f5c;
      color: #fff;
      .con {
        position: relative;
        .check {
          position: absolute;
          width: 112px;
          height: 16px;
          line-height: 16px;
          top: -50px;
          right: 0;
          font-size: 16px;
          background: url("~@/assets/img/right.png") no-repeat right center;
          cursor: pointer;
        }
        .car_model {
          display: flex;
          border-bottom: 1px solid #aaa;
          .item {
            flex: 1;
            padding-bottom: 40px;
            margin-bottom: 2px;
            height: 410px;
            .item_img {
              height: 266px;
              position: relative;
              img {
                position: absolute;
                left: 0%;
                top: 0%;
               
                // transform: translate(-50%, -50%);
              }
            }
            .item_text {
              padding: 20px 0;
              height: 104px;
              text-align: center;
              font-family: PingFang SC;
              .item_name {
                color: #fff;
                font-size: 16px;
                font-weight: 500;
              }
              .item_cs {
                margin: 13px 0 20px 0;
                font-size: 14px;
                font-weight: 400;
              }
              .item_botton {
                margin: 0 auto 20px;
                visibility: hidden;
                width: 110px;
                height: 40px;
                line-height: 38px;
                background: #323f5c;
                color: #fff;
                font-size: 12px;
                cursor: pointer;
              }
            }
          }
          .item:hover {
            margin-bottom: 0px;
            border-bottom: 2px solid #fff;
            
            .item_img {
              background-color: #fff;
              transition: max-height 0.9s linear;
            }
            .item_text {
              background-color: #f5f5fa;
              .item_name {
                color: #333;
              }
              .item_cs {
                color: #999;
              }
              .item_botton {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
}

.two {
  .con {
    display: flex;
    height: 424px;
  }
  .left,
  .right {
    flex: 1;
  }
  .father {
    position: relative;
  }
  .child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 104px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 19px;
    text-align: center;
    .button {
      margin-top: 26px;
      width: 144px;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      border: 1px solid #fff;
      cursor: pointer;
    }
  }
  .right {
    display: flex;
    flex-wrap: wrap;
    .right_item {
      width: 290px;
      height: 207px;
      margin-left: 10px;
    }
  }
}

.three {
  padding: 60px 0 !important;
  background-color: #f9f9fb !important;
  .con {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 420px;
    .three_item {
      width: 550px;
      height: 156px;
      padding: 22px 20px;
      background-color: #fff;
      .title {
        background: url("~@/assets/img/q.png") no-repeat center left;
        padding-left: 30px;
        height: 19px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 19px;
        .count {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #fabe00;
          line-height: 19px;
        }
      }
      .answer {
        padding-top: 19px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
      }
    }
  }
}

.four {
  padding-bottom: 10px !important;
  .con {
    .title {
      padding: 0 76px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      div {
        width: 246px;
        height: 44px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 44px;
        text-align: center;
        border: 1px solid #fabe00;
        cursor: pointer;
      }
    }
    .four_content {
      padding-top: 40px;
      .intro {
        font-size: 14px;
        position: relative;
        .img {
          width: 394px;
          height: 325px;
        }
        .html {
          padding-left: 20px;
          padding-bottom: 20px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 21px;
        }
        .more {
          position: absolute;
          bottom: 10px;
          right: 0;
          padding-right: 12px;
          color: #999;
          background: url("~@/assets/img/right.png") no-repeat center right;
          cursor: pointer;
        }
      }
      .content_item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        .item_div {
          cursor: pointer;
          display: flex;
          width: 570px;
          margin-bottom: 20px;
          .item_div_img {
            width: 258px;
            height: 258px;
          }
          .item_div_right {
            padding-left: 20px;
            color: #222222;
            .right_time {
              padding-bottom: 14px;
              border-bottom: 1px solid #f5f5fa;
              .right_date {
                width: 75px;
                height: 19px;
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: bold;
                line-height: 21px;
                .slash {
                  float: right;
                  height: 21px;
                  width: 0;
                  border: 1px solid #ccc;
                  transform: rotate(30deg) translateY(-2px);
                }
              }
              .right_year {
                float: right;
                font-size: 16px;
                font-family: PingFang SC;
                // font-weight: bold; 
                line-height: 21px;
                color:#ccc;
              }
            }
            .right_content {
              h6 {
                margin: 15px 0;
                height: 17px;
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 19px;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 280px;
                white-space: nowrap;
                font-weight: 600;
              }
              & > p {
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 21px;
                height: 100px;
                overflow: hidden;
              }
            }
          }
        }
        .more {
          position: absolute;
          right: 0;
          bottom: -20px;
          padding-right: 12px;
          font-size: 14px;
          color: #999;
          background: url("~@/assets/img/right.png") no-repeat center right;
          cursor: pointer;
        }
      }
    }
  }
}

.five {
  .con {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* height: 220px; */
    .five_item {
      width: 285px;
      // height: 100px;
      overflow: hidden;
    }
  }
}
</style>


