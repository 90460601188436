<template>
  <div class="all">
    <div class="rent_car">
      <!-- 标签头 -->
      <div class="tabs">
        <div class="tabs_list">
          <div v-for="(item,index) in carSort" :key="index" @click="clickTab(item)" :class="`tab_item ${activeTab === item.dictValue ? 'tab_active' : ''}`">{{item.dictValue}}</div>
        </div>
      </div>

      <!-- 标签内容 -->
      <div class="car_list" v-if="activeCar === ''">
        <div class="list_title">{{activeTab}}</div>
        <div class="list_con">
          <div v-for="(item,index) in carList" :key="index" class="con_item">
            <div class="item_tips">{{item.seriesName}} | {{item.carDescribe}}</div>
            <div class="item_img">
              <img :src="item.src" height="200px" alt="">
            </div>
            <div class="item_pro">
              <div class="pro_left">
                <div class="left_name">{{item.carLabelVal}}</div>
                <div class="left_price">
                  <span class="price_icon">￥</span><span class="price_count">{{item.price}}</span>
                </div>
              </div>
              <div class="pro_right" @click="showCar(item)">
                查看详情
              </div>
            </div>
          </div>
        </div>
        <div class="list_page">
          <div class="page_count">共{{carList.length}}条数据</div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.pageNum" background :page-sizes="[1,2,4, 10, 20, 50]" :page-size="params.pageSize" layout="prev, pager, next, sizes, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
      <div v-else class="car_detail">
        <div class="detail_title">{{activeTab + '-' + activeCar.seriesName}}</div>
        <div class="close" @click="activeCar=''">关闭</div>
        <div class="detail_nav">
          <div class="detail_current_img">
            <img :src="activeCar.currentImg" alt="">
          </div>
          <div class="detail_img_list">
            <slider ref="slider" :options="options" @slide='slide' @tap='onTap' @init='onInit' class="show_list">
              <slideritem v-for="(item,index) in activeCar.src" :key="index" class="show_item">
                <img :src="item" height="170px" max-width="170px" alt="" @click="setCurrent(item)">
              </slideritem>
              <!-- 设置loading,可自定义 -->
            </slider>
            <div class="next_img left" @click="sildLast"></div>
            <div class="next_img right" @click="sildeNext"></div>
          </div>
          <div class="detail_produce">
            <div class="detail_name">{{activeCar.name}}</div>
            <div class="detail_mess">{{activeCar.engine}} | {{activeCar.layout}}</div>
            <div class="detail_mess">说明： {{activeCar.mealRemark}}</div>
            <div class="detail_mess">备注：{{ activeCar.remark}}</div>
            <div class="detail_price">
              <span class="price_icon">￥</span><span class="price_count">{{activeCar.orginPrice}}</span> /天
            </div>
          </div>
          <div class="detail_button" @click="commitkefu">
            立即咨询
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'
export default {
  data() {
    return {
      activeTab: '全部车辆',
      activeCar: '',
      carSort: [
        {
          "groupId": "3",
          "version": 0,
          "dictId": 0,
          "dictValue": "全部车辆",
          "orderby": 1,
          "subDictItems": []
        },
        {
          "groupId": "3",
          "version": 1,
          "dictId": 1,
          "dictValue": "轿车",
          "orderby": 1,
          "subDictItems": []
        },
        {
          "groupId": "3",
          "version": 1,
          "dictId": 2,
          "dictValue": "SUV",
          "orderby": 2,
          "subDictItems": []
        },
        {
          "groupId": "3",
          "version": 1,
          "dictId": 3,
          "dictValue": "MPV(商务车)",
          "orderby": 3,
          "subDictItems": []
        },
        {
          "groupId": "3",
          "version": 1,
          "dictId": 4,
          "dictValue": "中巴",
          "orderby": 4,
          "subDictItems": []
        },
        {
          "groupId": "3",
          "version": 1,
          "dictId": 5,
          "dictValue": "大巴",
          "orderby": 5,
          "subDictItems": []
        },
        {
          "groupId": "3",
          "version": 1,
          "dictId": 6,
          "dictValue": "面包车",
          "orderby": 6,
          "subDictItems": []
        }
      ],
      carList: [
        { power: '动力', src: require('@/assets/img/che.png'), count: '座位数量', name: '车型名称', price: '394' },
        { power: '动力', src: require('@/assets/img/che.png'), count: '座位数量', name: '车型名称', price: '394' },
        { power: '动力', src: require('@/assets/img/che.png'), count: '座位数量', name: '车型名称', price: '394' },
        { power: '动力', src: require('@/assets/img/che.png'), count: '座位数量', name: '车型名称', price: '394' },
        { power: '动力', src: require('@/assets/img/che.png'), count: '座位数量', name: '车型名称', price: '394' },
        { power: '动力', src: require('@/assets/img/che.png'), count: '座位数量', name: '车型名称', price: '394' },
        // { power: '动力', src: require('@/assets/img/che.png'), count: '座位数量', name: '车型名称', price: '394' },
        // { power: '动力', src: require('@/assets/img/che.png'), count: '座位数量', name: '车型名称', price: '394' },
        // { power: '动力', src: require('@/assets/img/che.png'), count: '座位数量', name: '车型名称', price: '394' },
      ],
      carDetail: {
        currentImg: require('@/assets/img/pic1_1.png'),
        src: [require('@/assets/img/pic2_1.png'), require('@/assets/img/pic2_1.png'), require('@/assets/img/pic2_1.png'), require('@/assets/img/pic2_1.png'), require('@/assets/img/pic2_1.png'), require('@/assets/img/pic2_1.png')],
        name: '2021大众帕萨特',
        engine: '1.4T自动',
        layout: '三厢五座',
        price: '394'
      },
      //滑动配置[obj]
      options: {
        currentPage: 0,//当前页码
        thresholdDistance: 200,//滑动判定距离
        thresholdTime: 5000,//滑动判定时间
        pagination: false,//是否展示分页
        // autoplay: 1000,//自动滚动[ms]
        // loop:true,//循环滚动
        // direction:'vertical',//方向设置，垂直滚动
        // loopedSlides:1,//无限滚动前后遍历数
        infinite: 4,
        slidesToScroll: 1,//每次滑动项数
        timingFunction: 'ease', // 滑动方式
        speed: 300, // 滑动时间
      },
      params: {
        "carType": 0,
        "orderBy": "",
        "pageNum": 1,
        "pageSize": 6,
        "wayId": 0
      },
      total: 0,
      pageSizes: 6,
      pagerCount: 5
    }
  },
  components: {
    slider,
    slideritem
  },
  mounted() {
    let { isDetail } = this.$route.params
    console.log(this.$route.params)
    isDetail === 2 && (this.activeCar = this.carDetail);

    (async () => {
      window.localStorage.removeItem('wayId')
      let res = await this.$http.websiteCompanyDetail({})

      if (res.code != 0) {
        let wayId = res.body.wayId
        this.$http.queryDictGroup({
          dataFlag: 1,
          groupId: 3,
          version: 0
        }).then(res => {
          console.log(res)
          this.carSort = res.body
          this.carSort.unshift({
            "groupId": "3",
            "version": 0,
            "dictId": 0,
            "dictValue": "全部车辆",
            "orderby": 1,
            "subDictItems": []
          },)
        })
        window.localStorage.setItem('wayId', wayId)
        this.params.wayId = wayId
        this.list(this.params)
      }
    })()
  },
  methods: {
    setCurrent(url) {
      this.activeCar.currentImg = url
    },
    list(data) {
      this.$http.clientQueryModelSettingList(data).then(res => {

        this.carList = res.body.map(item => {
          return {
            ...item,
            src: window.baseUrl + item.mainImage,
            price: item.rentFee
          }
        })
        // alert(this.carList.length)
        this.total = res.total
      })
    },
    clickTab(item) {
      this.activeCar = ''
      this.activeTab !== item.dictValue && (this.activeTab = item.dictValue)
      this.params.carType = item.dictId
      this.list(this.params)
    },
    showCar(item) {
      this.$http.clientQueryModelSettingDetail({ msId: item.msId }).then(res => {
        let json = res.body;
        json.src = json.detailImageList.map(item => window.baseUrl + item)
        json.currentImg = window.baseUrl + json.mainImage
        let info = json.priceSettingList
        console.log(info)
        json.name = json.seriesName
        if (info && info.length) {
          json.rentFee = info.orginPrice
          for (let key in info[0]) {
            json[key] = info[0][key]
          }

        }
        this.activeCar = json
        console.log(this.activeCar)
      })
      this.activeCar = this.carDetail

      // this.carDetail= {
      //   currentImg: require('@/assets/img/pic1_1.png'),
      //   src: item.detailImageList.map(item=>window.baseUrl+item),
      //   name: item.seriesName,
      //   // engine: '1.4T自动',
      //   layout: item.carDescribe,
      //   price: item.rentFee
      // }
    },
    commitkefu() {
      this.Bus.$emit('kefu', '111')
    },
    handleSizeChange(size) {
      this.params.pageSize = size
      this.pageSizes = size
      this.list(this.params)
      // console.log(e)
    },
    handleCurrentChange(pageNum) {
      this.params.pageNum = pageNum
      this.list(this.params)
    },
    sildeNext() {//上一页
      this.$refs.slider.$emit('slideNext')
    },
    sildLast() {// 下一页
      this.$refs.slider.$emit('slidePre')
    },
    // 监听事件
    slide() {
      // console.log(data)
    },
    onTap(data) {
      console.log(data)
    },
    onInit() {
      // console.log(data)
    }
  },


}
</script>

<style lang="scss" scope>
.all {
  width: 100%;
  height: 100%;
  padding: 20px 0;
  background-color: #f9f9f9;
}
.rent_car {
  margin: auto;
  width: 1200px;
  display: flex;
}
.tabs {
  margin-right: 20px;
  .tabs_list {
    background-color: #fff;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .tab_item {
      font-size: 16px;
      font-weight: 500;
      padding: 15px 33px;
      cursor: pointer;
      white-space: nowrap;
    }
    .tab_active {
      color: #fabe00;
    }
  }
}
.car_list {
  flex: 1;
  .list_title {
    padding-left: 10px;
    border-left: 4px solid #fabe00;
    height: 18px;
    line-height: 18px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
  }
  .list_con {
    width: calc(100% + 20px);
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    .con_item {
      margin: 0 20px 20px 0;
      width: 336px;
      // height: 236px;
      background: #ffffff;
      position: relative;
      &:hover {
        cursor: pointer;
        box-shadow: 0 0 20px #ddd;
      }
      .item_tips {
        position: absolute;
        left: 20px;
        top: 20px;
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #fff;
      }
      .item_img {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .item_pro {
        padding: 20px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .pro_left {
          .left_name {
            height: 18px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-bottom: 9px;
          }
          .left_price {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            .price_icon {
              font-size: 18px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #f63434;
            }
            .price_count {
              font-size: 24px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #f63434;
            }
          }
        }
        .pro_right {
          // width: 110px;
          padding: 12px 24px;
          background: #323f5c;
          font-size: 16px;
          line-height: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}

.car_detail {
  flex: 1;
  overflow: hidden;
  position: relative;
  .close {
    position: absolute;
    left: 50%;
    top:-2px;
    transform: translateX(-50%);
    color: #524f4f;
    font-weight: 900;
    &:hover{
      cursor: pointer;
     
    }
  }
  .detail_title {
    padding-left: 10px;
    border-left: 4px solid #fabe00;
    height: 18px;
    line-height: 18px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
  }
  .detail_nav {
    width: 100%;
    margin: 20px 0;
    background-color: #fff;
    position: relative;
    .detail_current_img {
      width: 100%;
      height: 457px;
      img {
        width: 100%;
        // height: 100%;
      }
    }
    .detail_produce {
      position: absolute;
      left: 20px;
      top: 20px;

      .detail_name {
        height: 18px;
        line-height: 18px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #fff;
      }
      .detail_mess {
        height: 14px;
        line-height: 14px;
        margin: 13px 0;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #fff;
      }
      .detail_price {
        height: 24px;
        line-height: 24px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #fff;
        .price_icon {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #f63434;
        }
        .price_count {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #f63434;
        }
      }
    }
    .detail_button {
      position: absolute;
      right: 20px;
      top: 20px;
      padding: 12px 24px;
      background: #323f5c;
      font-size: 16px;
      line-height: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
    .detail_img_list {
      padding: 20px 23px;
      width: calc(100% - 46px);
      height: 180px;
      overflow: hidden;
      position: relative;
      .show_list {
        width: 100%;
        height: 100%;

        // display: flex;
        // justify-content: space-between;
        .show_item {
          width: 236px;
          display: inline-block;
          margin-left: 20px;
          cursor: pointer;
        }
        .show_item:nth-child(1) {
          margin: 0;
        }
      }
      .next_img {
        position: absolute;
        top: 50%;
        width: 30px;
        height: 30px;
        transform: translateY(-50%);
        z-index: 99;
        cursor: pointer;
      }
      .left {
        left: 23px;
        background: url("~@/assets/img/left.png") no-repeat center center;
      }
      .right {
        right: 23px;
        background: url("~@/assets/img/right_1.png") no-repeat center center;
      }
    }
  }
}

// 分页器
.list_page {
  margin-top: 10px;
  display: flex;
  justify-content: right;
  align-items: center;
  .el-pagination.is-background {
    .btn-next,
    .btn-prev {
      // box-sizing: border-box;
      background-color: #fff;
      border: 1px solid #cccccc;
      border-radius: 4px;
    }
    .el-pager li:not(.disabled) {
      background-color: #fff; // 进行修改未选中背景和字体
      color: #ccc;
      border: 1px solid #cccccc;
      border-radius: 4px;
    }
    .el-pager li:not(.disabled).active {
      background-color: #323f5c; // 进行修改选中项背景和字体
      color: #fff;
      border: 1px solid #323f5c;
      border-radius: 4px;
    }
  }
}
</style>
