import request from '@/utils/request'
// import apiMode from '@/api/api.mode.js'
// import request from 'axios'
// eslint-disable-next-line no-undef
const qs = require('qs')

// 路径枚举类
/**
 * @key  serverTypeEnumeration  服务枚举类型
 *
*/
// const serverTypeEnumeration = {
//   'webpc': process.env.VUE_APP_WEBPC || '',
//   'org': process.env.VUE_APP_ORG || '',
//   'query': process.env.VUE_APP_QUERY || '',
//   'outflow': process.env.VUE_APP_OUTFLOW || ''
// }

// query参数，数组可序列化
const paramsSerializer = function (params) {
  return qs.stringify(params, { arrayFormat: 'repeat' })
}
// serverTypeEnumeration[serverType] +
// serverType = 'webpc'
// 公用的请求方法
// 注意一下,headers不能为undefined，不然会覆盖axios的默认headers值成为undefined
const publicReuqest = function ({ url, method, params, data, headers = {}, qs = false }) {
  let basicObj = {
    url: url,
    method,
    params,
    data,
    headers
  }
  qs && (basicObj.paramsSerializer = paramsSerializer)
  return request(basicObj)
}

export default {
  /**
   * args 参数是一个对象
  */
  // 公用 get 请求
  get (args) {
    return publicReuqest(Object.assign({}, args, { method: 'get' }))
  },
  // 公用 post 请求
  post (args) {
    return publicReuqest(Object.assign({}, args, { method: 'post' }))
  },
  // 公用 put 请求
  publicPut (args) {
    return publicReuqest(Object.assign({}, args, { method: 'put' }))
  },
  // 公用 delete 请求
  publicDelete (args) {
    return publicReuqest(Object.assign({}, args, { method: 'delete' }))
  },
  // 公用 url的get 请求
  publicUrlGet (url, params) {
    return request({
      url: `${url}?${params}`,
      method: 'get'
    })
  },
  // 公用 上传文件 请求
  filePost (url, data, params) {
    return request({
      url: url,
      method: 'post',
      params,
      data,
      responseType: 'blob'
    })
  },
  // 公用 获取文件数据 请求
  fileGet (url, params) {
    return request({
      url: url,
      method: 'get',
      params,
      responseType: 'blob'
    })
  }

}
