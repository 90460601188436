import axios from 'axios'

console.log('查看环境',process.env.NODE_ENV)
let env=process.env.NODE_ENV
let base={
  production:'http://43.136.58.34:8080/driver/'||'https://api.ediaodu.top:8080/driver',
  development:'http://43.136.58.34:8080/driver/' //'http://192.168.2.177:8080/driver',//'http://43.136.58.34:8080/driver/'
}

axios.defaults.baseURL =base[env]
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // config.headers.token='9x152Y6JhcRszY51'
  
  config.params = {
    ...config.params,
    wayId:window.localStorage.getItem('wayId')
    // appkey: 'dongmeiqi_i_1545272862243'
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

//响应拦截器
axios.interceptors.response.use(response=> {
      const res = response.data
      if (res.code !== 200) {
          //请求失败（包括token失效，302，404...根据和后端约定好的状态码做出不同的处理）
          return res
      }else{
          //请求成功
          return res.data
      }
  },
error => {
  //处理错误响应
  return Promise.reject(error)
})



export default axios
