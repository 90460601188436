<template>
  <el-container class="layout-default">
    <el-header height="72">
      <Header @commitkefu="commitkefu" />
    </el-header>
    <el-main>
      <router-view  ></router-view>
    </el-main>
    <el-footer>
      <Footer />
    </el-footer>
    <el-dialog title="客服热线：400-000-0000"
               :visible.sync="dialogVisible"
               width="280px">
      <!-- <div class="dial_mess">
        <div class="mess_more">查看更多消息</div>
        <div class="mess_time">XXXX通过客服接口1发起会话 14:12:36</div>
        <div class="mess_kefu">
          <div class="kefu_time">2019-08-28 10:34:00</div>
          <div class="kefu">
            <div class="kefu_img"></div>
            <div class="kefu_talk_before"></div>
            <div class="kefu_talk">你好！我是xx公司的机器人小助手，你可以像我体温，试试输入问题或关键词，我会努力回答或转人工客服支持。</div>
          </div>
        </div>
        <div class="mess_user">
          <div class="user_time">2019-08-28 10:34:00</div>
          <div class="user">
            <div class="user_talk">好的，谢谢</div>
            <div class="user_talk_after"></div>
            <div class="user_img"></div>
          </div>
        </div>
      </div>
      <div class="dial_text">
        <div class="gongneng">
          <img src="@/assets/img/biaoqing.png"
               alt="表情"
               title="表情">
          <img src="@/assets/img/tupian.png"
               alt="图片"
               title="图片">
          <img src="@/assets/img/gouwu.png"
               alt="">
          <img src="@/assets/img/wenjian.png"
               alt="文件"
               title="文件">
          <img src="@/assets/img/caijian.png"
               alt="截屏"
               title="截屏">
        </div>
        <textarea placeholder="请输入咨询信息">
        </textarea>
        <div class="button">发送</div>
      </div> -->
    </el-dialog>
  </el-container>
</template>
<script>

import Header from '@/components/header/index.vue';
import Footer from '@/components/footer/index.vue'
export default {
  data () {
    return {
      dialogVisible: false,
      isRouterAlive:true
    }
  },
  provide(){
		return{
			reload:this.reload
		}
	},
  mounted () {
    this.Bus.$on('kefu', () => {
      this.commitkefu()
    })
  },
  methods: {
    reload() {
			this.isRouterAlive=false
			this.$nextTick(function(){
				this.isRouterAlive=true
			})
		},
    commitkefu () {
      if (this.dialogVisible === false) {
        this.dialogVisible = true;
      }
    }
  },
  components: {
    Header,
    Footer
  }
}
</script>
<style lang="scss">
.layout-default {
  .el-main {
    overflow: inherit;
  }
  min-width: 1200px;
  .el-dialog__header {
    // background: url("~@/assets/img/logoa.png") no-repeat 20px center;
    padding: 22px 20px;
    text-align: center;
    background-color: #323f5c;
    .el-dialog__title {
      font-size: 14px;
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 22px;
      right: 20px;
      font-size: 16px;
      .el-dialog__close {
        color: #fff;
      }
    }
  }
  .el-dialog__body {
    padding: 0;
  }
  .dial_mess {
    height: 332px;
    padding: 20px;
    background: #f5f5f5;
    .mess_more {
      text-align: center;
      color: #338aff;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      cursor: pointer;
    }
    .mess_time {
      width: fit-content;
      margin: 13px auto;
      padding: 8px 28px;
      line-height: 14px;
      height: 14px;
      font-size: 14px;
      text-align: center;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      background: #d6d6d6;
      border-radius: 15px;
    }
  }
  .dial_text {
    height: 190px;
    padding: 20px;
    .gongneng {
      display: flex;
      height: 20px;
      img {
        margin-right: 20px;
      }
    }
    textarea {
      margin: 20px 0;
      padding: 20px;
      width: 640px;
      height: 52px;
      font-size: 16px;
      line-height: 16px;
      background: #f5f5f5;
      border-radius: 4px;
      border: 0 solid #f5f5f5;
      outline: none;
      resize: none;
    }
    .button {
      float: right;
      width: 110px;
      height: 36px;
      line-height: 34px;
      background: #323f5c;
      border-radius: 4px;
      color: #fff;
      text-align: center;
      cursor: pointer;
    }
  }
}
.mess_kefu,
.mess_user {
  margin-top: 30px;
  .kefu_time,
  .user_time {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
  }
}
.kefu_time {
  padding-left: 70px;
}
.mess_user {
  text-align: right;
  .user_time {
    padding-right: 70px;
  }
}
.kefu,
.user {
  margin-top: 13px;
  display: flex;
  align-items: center;
}
.kefu {
  justify-content: left;
}
.user {
  justify-content: right;
}
.kefu_img,
.user_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.kefu_img {
  margin-right: 20px;
  background: url("~@/assets/img/touxiang1.png") no-repeat center center;
}
.user_img {
  margin-left: 20px;
  background: url("~@/assets/img/touxiang2.png") no-repeat center center;
}
.kefu_talk,
.user_talk {
  box-sizing: border-box;
  max-width: 464px;
  padding: 12px 14px;
  background: #fff;
  border-radius: 4px;
}
.kefu_talk_before {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid #fff;
  border-bottom: 10px solid transparent;
}
.user_talk_after {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 10px solid #fff;
  border-bottom: 10px solid transparent;
}
@import "@/assets/css/public/layout.scss";
</style>


