import Vue from 'vue'
import Router from 'vue-router'
import defaultPage from '@/layout/default.vue'
import Index from '@/page/index.vue'
import longRent from '@/page/longRent/index.vue'
import rentCar from '@/page/rentCar/index.vue'
import detail from '@/page/detail/index.vue'

Vue.use(Router)

// //获取原型对象上的push函数
// const originalPush = Router.prototype.push
// //修改原型对象中的push方法
// Router.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => err)
// }

export default new Router({
  mode: 'history',
  linkActiveClass: 'tabsActive',
  routes: [
    {
      path: '/',
      name: 'default',
      component: defaultPage,
      redirect: '/index',
      children: [
        {
          path: 'index',
          name: 'index',
          component: Index
        },
        {
          path: 'longRent',
          name: 'longRent',
          component: longRent
        },
        {
          path: 'rentCar',
          name: 'rentCar',
          component: rentCar
        },
        {
          path: 'detail',
          name: 'detail',
          component: detail
        }
      ]
    }
  ],
  scrollBehavior (to, form, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})