import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/main.css'
import ajax from '@/api/interface'
window.baseUrl='https://hrong-driver.oss-cn-guangzhou.aliyuncs.com/'
Vue.use(ElementUI)
Vue.prototype.Bus = new Vue()
Vue.config.productionTip = false

import store from '@/store/index';
Vue.prototype.$http=ajax
console.log(ajax)
new Vue({
  router,
  render: h => h(App),
  store
}).$mount('#app')
