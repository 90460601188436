// clientQueryFaqList
// http://43.136.58.34:8080/driver/doc.html#/default/home-page-manager-controller/clientQueryFaqListUsingPOST
import http from './../public';
export default {
  clientQueryFaqList: (data)=>http.post({url:'/homepage/clientQueryFaqList',data}), //小程序查询问答列表
  clientQueryRotateImgList: (data)=>http.post({url:'/homepage/clientQueryRotateImgList',data}), //轮播列表
  clientQueryCompanyDetail: (data)=>http.post({url:'/homepage/clientQueryCompanyDetail',data}), //公司详情
  clientQueryCooperatives: (data)=>http.post({url:'/homepage/clientQueryCooperatives',data}), //合作伙伴
  clientCompanyArticleList: (data)=>http.post({url:'/homepage/clientCompanyArticleList',data}), //公司动态列表
  clientCompanyArticle:(data)=>http.post({url:'/homepage/clientCompanyArticle',data}), //公司动态列表
  clientQueryModelSettingList: (data)=>http.post({url:'/homepage/clientQueryModelSettingList',data}), //查询车型列表
  clientQueryModelSettingDetail: (data)=>http.post({url:'/homepage/clientQueryModelSettingDetail',data}), //查詢
  websiteCompanyDetail: (data)=>http.post({url:'/homepage/website/websiteCompanyDetail',data}), //主
  queryDictGroup: (data)=>http.post({url:'/common/queryDictGroup',data}), //车型类型
  websiteCompanyActivity: (data)=>http.post({url:'/homepage/website/websiteCompanyActivity',data}), //公司官网优惠活动详情
  websiteCompanyStrategy:(data)=>http.post({url:'/homepage/website/websiteCompanyStrategy',data}), //公司官网攻略活动详情
  websiteCompanyActivityList: (data)=>http.post({url:'/homepage/website/websiteCompanyActivityList',data}), //公司官网优惠活动列表
  websiteCompanyStrategyList: (data)=>http.post({url:'/homepage/website/websiteCompanyStrategyList',data}), //公司官网租车攻略列表
}